import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Tab1.module.css';
import { useState, useEffect } from 'react';
import TitleLine from './TitleLine';
import StatGrid from './StatGrid';

function Tab1() {

  const lotteryData = [
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
      replay: '回放',
    },
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
      replay: '回放',
    },
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
      replay: '回放',
    },
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
      replay: '回放',
    },
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
      replay: '回放',
    },

  ];

  const options = ["全站APP", "体育APP", "真人APP", "登录器"];
  const defaultIndex = options.indexOf("全站APP"); // Default index for 全站APP

  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  const handleOptionSelected = (index) => {
    console.log('Selected index:', index);
    setSelectedIndex(index);
  };

  return (
    <>
      <ImageSlider />
      {/* <BorderedContainer /> */}
      <ContactSection />

      <TitleLine title="澳門六合彩" subtitle="第2024220期 截止時間: 2024-08-07 21:15:00" />
      <LotteryUI />

      <LotteryTable lotteryData={lotteryData} />

      {/* <HeaderAppSection /> */}
      <TitleLine title="预测APP图库" subtitle="独家原生APP支持全部移动端" />
      <div>
        <SegmentedControl
          options={options}
          selectedIndex={selectedIndex}
          onOptionSelected={handleOptionSelected}
        />
        {/* Conditionally render content based on selected index */}
        {selectedIndex === 0 && <AppFirstTab />}
        {selectedIndex === 1 && <AppSecondTab />}
        {selectedIndex === 2 && <AppThirdTab />}
        {selectedIndex === 3 && <AppFourthTab />}
      </div>

      {/* <HeaderStatisticSection /> */}
      <TitleLine title="优质服务" subtitle="香港六合彩全心全意为您提供最优质的服务" />
      {/* <StatisticView /> */}
      <StatGrid />

      {/* <HeaderSlideImageSection /> */}
      <TitleLine title="预测图库" subtitle="您想要的开云都有，带给您丰富的游戏体验" />
      <ImageGrid />
    </>
  );
}

function ContactSection() {
  return (
    <>
      <div className={styles.outerContactContainer}>
        <div className={styles.contactContainer}>
          <div className={styles.contactItem}>
            <img src="/ic1.png" alt="Icon 1" className={styles.contactIcon} />
            <span className={styles.contactTitle}>香港六合彩全站APP</span>
            <img src="/ic5.png" alt="Forward" className={styles.contactForwardIcon} />
          </div>
          <div className={styles.contactItem}>
            <img src="/ic2.png" alt="Icon 2" className={styles.contactIcon} />
            <span className={styles.contactTitle}>香港六合彩APP</span>
            <img src="/ic5.png" alt="Forward" className={styles.contactForwardIcon} />
          </div>
        </div>

        <div className={styles.contactLine}></div>

        <div className={styles.contactDesc}>添加以下专属经理联系方式，领取免费彩金、包赔注单、精美礼品</div>

        <div className={styles.bottomSection}>
          <div className={styles.bottomItem}>
            <img src="/ic4.png" alt="Bottom Icon 1" className={styles.bottomIcon} />
            <span className={styles.bottomText}>LetsTalk客服号：t251840254</span>
            <button className={styles.copyButton}>复制</button>
            <button className={styles.downloadButton}>下载</button>
          </div>
          <div className={styles.bottomItem}>
            <img src="/ic6.png" alt="Bottom Icon 2" className={styles.bottomIcon} />
            <span className={styles.bottomText}>24小时在线客服</span>
            <button className={styles.contactButton}>点击联系</button>
          </div>
        </div>
      </div>
    </>
  );
}

function ImageSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: dots => (
      <div className={styles.customDots}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div className={styles.customDot}></div>
    )
  };

  const images = [
    'https://macaujc.org/assets/swiper1-fc687c21.jpeg',
    'https://macaujc.org/assets/swiper2-3279e52f.jpeg',
    'https://macaujc.org/assets/swiper3-8c035527.jpeg',
  ];

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        {images.map((url, index) => (
          <div key={index} className={styles.slide}>
            <img src={url} alt={`Slide ${index}`} className={styles.image} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

const Ball = ({ number, image }) => {
  return (
    <div className={styles.lotteryBall} style={{
      backgroundImage: `url(${image})`,
    }}>
      {number}
    </div>
  );
};

const Timer = ({ deadline }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className={styles.timerContainer}>
      {timeLeft.hours
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
      <span>:</span>
      {timeLeft.minutes
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
      <span>:</span>
      {timeLeft.seconds
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
    </div>
  );
};

const LotteryUI = () => {
  const balls = [
    { number: 8, color: '/red.png' },
    { number: 14, color: '/blue.png' },
    { number: 16, color: '/green.png' },
    { number: 37, color: '/blue.png' },
    { number: 28, color: '/green.png' },
    { number: 26, color: '/blue.png' },
    { number: 40, color: '/red.png' },
  ];

  const deadline = '2024-08-30T21:15:00';

  return (
    <div className={styles.lotteryResultsContainer}>
      <div className={styles.lotteryContent}>
        <div className={styles.lotteryLeft}>
          <h3>澳門六合彩第 2024220 期</h3>
          <div className={styles.lotteryRow}>
            {balls.slice(0, -1).map((ball, index) => (
              <Ball key={index} number={ball.number} image={ball.color} />
            ))}
            <span className={styles.plusSign}>+</span>
            <Ball key={balls.length - 1} number={balls[balls.length - 1].number} image={balls[balls.length - 1].color} />
          </div>
        </div>
        <div className={styles.lotteryRight}>
          <Timer deadline={deadline} />
          <div style={{ marginTop: '20px' }}>
            <button style={{ padding: '10px 20px', marginRight: '10px', backgroundColor: '#e1a61f', color: '#fff' }}>開獎結果</button>
            <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>直播</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SmallBall = ({ number, image, description }) => {
  return (
    <div>
      <div className={styles.lotterySmallBall} style={{
        backgroundImage: `url(${image})`,
      }}>
        {number}
      </div>
      <div style={{ fontSize: '12px', paddingTop: '6px' }}>{description}</div>
    </div>
  );
};

const LotteryTable = ({ lotteryData }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tableHeaders = isMobile
    ? ['期號', '中獎號碼']
    : ['期號', '開獎時間', '中獎號碼', '開獎回放'];

  return (
    <table className={styles.lotteryTable}>
      <thead>
        <tr>
          {tableHeaders.map((header, index) => (
            <th key={header} className={`column-${index + 1}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {lotteryData.map((row, index) => (
          <tr key={index}>
            <td className={styles.lotteryTableColumn1}>{row.period}</td>
            <td className={styles.lotteryTableColumn2}>{row.openTime}</td>
            <td className={styles.lotteryTableColumn3}>
              <div className={styles.lotteryRow}>
                {row.numbers.slice(0, -1).map((ball, index) => (
                  <SmallBall key={index} number={row.numbers[index]} image={row.color[index]} description={row.desc[index]} />
                ))}
                <span className={styles.smallPlusSign}>+</span>
                <SmallBall key={row.numbers.length - 1} number={row.numbers[row.numbers.length - 1]} image={row.color[row.numbers.length - 1]} description={row.desc[index]} />
              </div>
            </td>
            <td className={styles.lotteryTableColumn4}>
              <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>回放</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

};

function ImageCard({ imageSrc, description }) {
  return (
    <div className={styles.imageCard}>
      <img src={imageSrc} alt="" className={styles.imageItem} />
      <p className={styles.description}>{description}</p>
    </div>
  );
}

function ImageGrid() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('http://localhost:5001/api/news-articles');
        const data = await response.json();
        console.log('Fetched data:', data); // Verify data fetched
        setImages(data); // Update state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    }

    fetchData();
  }, []);

  return (
    <div className={styles.imageGrid}>
      {images.map((image, index) => (
        <ImageCard key={index} imageSrc={image.image_url} description={image.title} />
      ))}
    </div>
  );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
  const handleOptionClick = (index) => {
    onOptionSelected(index);
  };

  return (
    <div className={styles.segmentedControl}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
          onClick={() => handleOptionClick(index)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

const AppContainer = ({ imageSrc, title, description }) => {
  return (
    <div className={styles.appContainer}>
      <div className={styles.appImageContainer}>
        <img src={imageSrc} alt="" width="100%" height="100%" />
      </div>
      <div className={styles.appContentContainer}>
        <h3 className={styles.appTitle}>{title}</h3>
        <p className={styles.appDesc}>{description}</p>
        {/* <img src='/qr.png' alt="" width="150px" height="150px" />
        <p style={{ fontSize: "16px", lineHeight: "1.5", fontWeight: "500", color: "#7881a4" }}>扫码下载</p> */}
      </div>
    </div>
  );
};

function AppFirstTab() {
  return <AppContainer
    imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image01-846f75266a0133f7f331a7e758657277.png"
    title="全站APP"
    description="全球首家一体化娱乐原生APP ，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有！"
  />
}

function AppSecondTab() {
  return <AppContainer
    imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image02-4930aebacbab720b4f6d1fc0014b452b.png"
    title="体育APP"
    description="业内赔率最高！覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。更有动画直播，让您体验轻松聊球，娱乐投注两不误。"
  />
}

function AppThirdTab() {
  return <AppContainer
    imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image03-15904e3f64df17482189c2f53f60daba.png"
    title="真人APP"
    description="最美荷官在线互动，最专业的百家乐玩法，国际标准的真人游戏摄影棚，全场监控，公正公开, 为玩家提供最公平的游戏环境，稳定而流畅的游戏服务，极致操作让您拥有身临其境的体验！"
  />
}

function AppFourthTab() {
  return <AppContainer
    imageSrc="https://www.slfqow.com:6443/api/ImageOptimizer/w=1920&q=80/https%3A%2F%2Fpos3img.uoenuvy.com%2Fimages%2Fnew_public%2Fweb%2Fbg%2Ffd%2Fcs%2Fcp5f2m6riolcp6tei8lg_723618.png"
    title="官方登录器"
    description="开云倾情打造，自主开发防劫持安全登录器。登录器支持 Windows｜MAC｜Android系统平台，使用登录器可直接访问开云WEB站点，有效防御和避免站点被拦截/劫持等问题。登录器安装简单，能给玩家提供安全的游戏环境体验！"
  />
}

export default Tab1;