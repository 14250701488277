// import styles from './Tab6.module.css';

// function Tab6() {
//     return (
//         <>
//             <div className={styles.title}>聯繫我們</div>
//             <div className={styles.description}>澳門六合彩將竭誠為您提供最及時、最便捷、及滿意的服務。若有問題可透過以下方式與我們取得聯繫。</div>
//             <LogoEmail />
//         </>
//     );
// }

// function LogoEmail() {
//     return (
//         <div className={styles.logo}>
//             <div>
//                 <img src="https://macaujc.org/assets/email-83749ae3.png" alt="" width="50px" />
//             </div>
//             <div className={styles.email}>email：macaujc853@gmail.com <span>星期二</span></div>
//         </div>
//     );
// }

// export default Tab6;

import styles from './Tab6.module.css';
import TitleLine from './TitleLine';
import { useState } from 'react';


function Tab6() {

    const options = ["全站APP", "体育APP", "真人APP", "登录器"];
    const defaultIndex = options.indexOf("全站APP"); // Default index for 全站APP

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <>
            <TitleLine title="预测APP图库" subtitle="独家原生APP支持全部移动端" />
            <div>
                <SegmentedControl
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
                {/* Conditionally render content based on selected index */}
                {selectedIndex === 0 && <AppFirstTab />}
                {selectedIndex === 1 && <AppSecondTab />}
                {selectedIndex === 2 && <AppThirdTab />}
                {selectedIndex === 3 && <AppFourthTab />}
            </div>
        </>
    );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const AppContainer = ({ imageSrc, title, description }) => {
    return (
        <div className={styles.appContainer}>
            <div className={styles.appImageContainer}>
                <img src={imageSrc} alt="" width="100%" height="100%" />
            </div>
            <div className={styles.appContentContainer}>
                <h3 className={styles.appTitle}>{title}</h3>
                <p className={styles.appDesc}>{description}</p>
                {/* <img src='/qr.png' alt="" width="150px" height="150px" />
        <p style={{ fontSize: "16px", lineHeight: "1.5", fontWeight: "500", color: "#7881a4" }}>扫码下载</p> */}
            </div>
        </div>
    );
};

function AppFirstTab() {
    return <AppContainer
        imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image01-846f75266a0133f7f331a7e758657277.png"
        title="全站APP"
        description="全球首家一体化娱乐原生APP ，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有！"
    />
}

function AppSecondTab() {
    return <AppContainer
        imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image02-4930aebacbab720b4f6d1fc0014b452b.png"
        title="体育APP"
        description="业内赔率最高！覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。更有动画直播，让您体验轻松聊球，娱乐投注两不误。"
    />
}

function AppThirdTab() {
    return <AppContainer
        imageSrc="https://www.slfqow.com:6443/_next/static/chunks/images/download_image03-15904e3f64df17482189c2f53f60daba.png"
        title="真人APP"
        description="最美荷官在线互动，最专业的百家乐玩法，国际标准的真人游戏摄影棚，全场监控，公正公开, 为玩家提供最公平的游戏环境，稳定而流畅的游戏服务，极致操作让您拥有身临其境的体验！"
    />
}

function AppFourthTab() {
    return <AppContainer
        imageSrc="https://www.slfqow.com:6443/api/ImageOptimizer/w=1920&q=80/https%3A%2F%2Fpos3img.uoenuvy.com%2Fimages%2Fnew_public%2Fweb%2Fbg%2Ffd%2Fcs%2Fcp5f2m6riolcp6tei8lg_723618.png"
        title="官方登录器"
        description="开云倾情打造，自主开发防劫持安全登录器。登录器支持 Windows｜MAC｜Android系统平台，使用登录器可直接访问开云WEB站点，有效防御和避免站点被拦截/劫持等问题。登录器安装简单，能给玩家提供安全的游戏环境体验！"
    />
}

export default Tab6;