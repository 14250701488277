import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

function Header() {
    return (
        <>
            <LogoHeader />
            {/* <NavigationBarHeader /> */}
        </>
    );
}

function LogoHeader() {
    return (
        <div className={styles.logo}>
            <div>
                <img src="https://macaujc.org/assets/logo-5fb13fd4.png" alt="" width="150px" />
            </div>
            <NavigationBarHeader />
            {/* <div className={styles.clock}>2024-07-30 15:00 <span>星期二</span></div> */}
        </div>
    );
}

function NavigationBarHeader() {
    const [selectedTab, setSelectedTab] = useState('/tab1'); // Default selected tab

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <nav className={styles.navBar}>
            <Link
                to="/tab1"
                className={selectedTab === '/tab1' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab1')}
            >
                首頁
            </Link>
            <Link
                to="/tab2"
                className={selectedTab === '/tab2' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab2')}
            >
                公告
            </Link>
            <Link
                to="/tab3"
                className={selectedTab === '/tab3' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab3')}
            >
                視頻
            </Link>
            <Link
                to="/tab4"
                className={selectedTab === '/tab4' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab4')}
            >
                FAQ
            </Link>
            <Link
                to="/tab5"
                className={selectedTab === '/tab5' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab5')}
            >
                關於
            </Link>
            <Link
                to="/tab6"
                className={selectedTab === '/tab6' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab6')}
            >
                APP
            </Link>
            <Link
                to="/tab7"
                className={selectedTab === '/tab7' ? styles.selectedNavLink : styles.navLink}
                onClick={() => handleTabClick('/tab7')}
            >
                扫码下载
            </Link>
        </nav>
    );
}

export default Header;
