import React from 'react';
import styles from './Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.line}></div>
            <p className={styles.label}>版權所有 不得轉載 © 2024 澳門六合彩</p>
        </footer>
    );
};

export default Footer;
