import React, { useState } from 'react';
import styles from './Tab3.module.css';

function Tab3() {

    const videoData = [
        { id: 2024220, url: 'https://macaujc.org/lottery/video/2024/2032/2024220.mp4', description: '澳門六合彩第 2024220 期 開獎視頻' },
        { id: 2024219, url: 'https://macaujc.org/lottery/video/2024/2032/2024219.mp4', description: '澳門六合彩第 2024219 期 開獎視頻' },
        { id: 2024218, url: 'https://macaujc.org/lottery/video/2024/2032/2024218.mp4', description: '澳門六合彩第 2024218 期 開獎視頻' },
        { id: 2024217, url: 'https://macaujc.org/lottery/video/2024/2032/2024217.mp4', description: '澳門六合彩第 2024217 期 開獎視頻' },
        { id: 2024216, url: 'https://macaujc.org/lottery/video/2024/2032/2024216.mp4', description: '澳門六合彩第 2024216 期 開獎視頻' },
        { id: 2024215, url: 'https://macaujc.org/lottery/video/2024/2032/2024215.mp4', description: '澳門六合彩第 2024215 期 開獎視頻' },
        { id: 2024214, url: 'https://macaujc.org/lottery/video/2024/2032/2024214.mp4', description: '澳門六合彩第 2024214 期 開獎視頻' },
        { id: 2024213, url: 'https://macaujc.org/lottery/video/2024/2032/2024213.mp4', description: '澳門六合彩第 2024213 期 開獎視頻' },
        { id: 2024212, url: 'https://macaujc.org/lottery/video/2024/2032/2024212.mp4', description: '澳門六合彩第 2024212 期 開獎視頻' },

        // { id: 2024211, url: 'https://macaujc.org/lottery/video/2024/2032/2024211.mp4', description: '澳門六合彩第 2024211 期 開獎視頻' },
        // { id: 2024210, url: 'https://macaujc.org/lottery/video/2024/2032/2024210.mp4', description: '澳門六合彩第 2024210 期 開獎視頻' },
        // { id: 2024209, url: 'https://macaujc.org/lottery/video/2024/2032/2024209.mp4', description: '澳門六合彩第 2024209 期 開獎視頻' },
        // { id: 2024208, url: 'https://macaujc.org/lottery/video/2024/2032/2024208.mp4', description: '澳門六合彩第 2024208 期 開獎視頻' },
        // { id: 2024207, url: 'https://macaujc.org/lottery/video/2024/2032/2024207.mp4', description: '澳門六合彩第 2024207 期 開獎視頻' },
        // { id: 2024206, url: 'https://macaujc.org/lottery/video/2024/2032/2024206.mp4', description: '澳門六合彩第 2024206 期 開獎視頻' },
        // { id: 2024205, url: 'https://macaujc.org/lottery/video/2024/2032/2024205.mp4', description: '澳門六合彩第 2024205 期 開獎視頻' },
        // { id: 2024204, url: 'https://macaujc.org/lottery/video/2024/2032/2024204.mp4', description: '澳門六合彩第 2024204 期 開獎視頻' },
        // { id: 2024203, url: 'https://macaujc.org/lottery/video/2024/2032/2024203.mp4', description: '澳門六合彩第 2024203 期 開獎視頻' },
    ];

    return (
        <>
            <div className={styles.container}>
                <div className={styles.leftText}>開獎視頻</div>
                <div className={styles.rightContainer}>
                    <div className={styles.rightText}>
                        即時開獎驗證開獎現場直播，同步播報中央電視臺1套視頻，開獎過程100%公開公正！
                    </div>
                </div>
            </div>
            <Tab3Button />
            <div className={styles.blackContainer}>
                <p className={styles.whiteText}>
                    澳門六合彩攪珠直播時間為每晚 21:30-21:38 (北京時間)，敬請查看。
                </p>
            </div>
            <SegmentedControlAndSearch />
            <div className={styles.containerThird}>
                <VideoGrid videoData={videoData} />
            </div>
        </>

    );
};

function Tab3Button() {
    return (
        <>
            <br />
            <div className={styles.buttonContainer}>
                <div className={styles.redButton}>開獎視頻</div>
            </div>
            <br />
        </>
    );
}

function SegmentedControlAndSearch() {
    const options = ["今年", "去年"];
    const defaultIndex = options.indexOf("今年"); // Default index for 今年

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <div className={styles.containerSecond}>
            {/* <div className={styles.label}>澳門六合彩開獎視頻</div> */}

            <div>
                <SegmentedControl
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
            </div>

            <div className={styles.searchField}>
                <input type="text" placeholder="輸入期號" />
                <button>🔍</button>
            </div>
        </div>
    );
};

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const VideoGrid = ({ videoData }) => {
    const VideoGridItem = ({ videoData }) => {
        const { description, url } = videoData;

        return (
            <div className={styles.videoGridItem}>
                <video src={url} controls width="100%" />
                <p className={styles.videoDescription}>{description}</p>
            </div>
        );
    };

    return (
        <div className={styles.videoGridContainer}>
            {videoData.map((videoItem) => (
                <VideoGridItem key={videoItem.id} videoData={videoItem} />
            ))}
        </div>
    );
};


export default Tab3;