import styles from './Tab2.module.css';
import React, { useState, useEffect } from 'react';

function Tab2() {

    const options = ["澳門六合彩 ", "接口調用 "];
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <>
            <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: '600', paddingTop: '20px', paddingLeft: '15%', paddingRight: '15%' }}>
                開獎公告
            </div>

            <div>
                <SegmentedControl
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
                {/* Conditionally render content based on selected index */}
                {selectedIndex === 0 && <LotteryResult />}
                {selectedIndex === 1 && <DescriptionAPI />}
            </div>
        </>
    );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

function LotteryResult() {

    const lotteryData = [
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },
        {
            period: '第2024220期',
            openTime: '2024-08-07 21:32:00',
            numbers: [1, 2, 3, 4, 5, 6, 7],
            desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
            color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
            replay: '回放',
        },

    ];

    return (
        <>
            <br />
            <LotteryUI />
            <SegmentedControlAndSearch />
            <LotteryTable lotteryData={lotteryData} />
        </>
    );
}

function DescriptionAPI() {
    return (
        <div className={styles.descAPI}>
            <h2>API 資訊</h2>
            <p>本網站提供查詢接口，供查詢開獎數據。</p>
            <p><strong>地址：</strong> https://api.macaujc.org/api/opencode/:id</p>
            <p><strong>方式：</strong> GET</p>
            <p><strong>返回：</strong> JSON</p>
            <br />
            <h3>調用示例</h3>
            <p>澳門六合彩: https://api.macaujc.org/api/opencode/2032</p>
            <br />
            <h3>返回示例</h3>
            <p>查詢澳門六合彩開獎數據</p>
            <br />
            <h3>返回字段說明</h3>
            <p>1、code: 消息編碼, 0 代表成功；</p>
            <p>2、message: 消息描述；</p>
            <p>3、data：具體開獎數據；issue：期號；openCode：開獎號碼；openTime：開獎時間。</p>
        </div>
    );
}

const LotteryUI = () => {
    const balls = [
        { number: 8, color: '/red.png' },
        { number: 14, color: '/blue.png' },
        { number: 16, color: '/green.png' },
        { number: 37, color: '/blue.png' },
        { number: 28, color: '/green.png' },
        { number: 26, color: '/blue.png' },
        { number: 40, color: '/red.png' },
    ];

    const deadline = '2024-08-30T21:15:00';

    return (
        <div className={styles.lotteryResultsContainer}>
            <div className={styles.lotteryContent}>
                <div className={styles.lotteryLeft}>
                    <h3>澳門六合彩第 2024220 期</h3>
                    <div className={styles.lotteryRow}>
                        {balls.slice(0, -1).map((ball, index) => (
                            <Ball key={index} number={ball.number} image={ball.color} />
                        ))}
                        <span className={styles.plusSign}>+</span>
                        <Ball key={balls.length - 1} number={balls[balls.length - 1].number} image={balls[balls.length - 1].color} />
                    </div>
                </div>
                <div className={styles.lotteryRight}>
                    <Timer deadline={deadline} />
                    <div style={{ marginTop: '20px' }}>
                        <button style={{ padding: '10px 20px', marginRight: '10px', backgroundColor: '#e1a61f', color: '#fff' }}>開獎結果</button>
                        <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>直播</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Ball = ({ number, image }) => {
    return (
        <div className={styles.lotteryBall} style={{
            backgroundImage: `url(${image})`,
        }}>
            {number}
        </div>
    );
};

const Timer = ({ deadline }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(deadline) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className={styles.timerContainer}>
            {timeLeft.hours
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
            <span>:</span>
            {timeLeft.minutes
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
            <span>:</span>
            {timeLeft.seconds
                .toString().padStart(2, '0')
                .split('')
                .map((digit, index) => (
                    <span key={index} className={styles.timeDigit}>
                        {digit}
                    </span>
                ))}
        </div>
    );
};

function SegmentedControlAndSearch() {
    const options = ["今年", "去年"];
    const defaultIndex = options.indexOf("今年"); // Default index for 今年

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <div className={styles.containerSecond}>
            {/* <div className={styles.label}>澳門六合彩開獎視頻</div> */}

            <div>
                <SegmentedControlSecond
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
            </div>

            <div className={styles.searchField}>
                <input type="text" placeholder="輸入期號" />
                <button>🔍</button>
            </div>
        </div>
    );
};

const SegmentedControlSecond = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControlSecond}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segmentSecond} ${selectedIndex === index ? styles.selectedSecond : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const SmallBall = ({ number, image, description }) => {
    return (
        <div>
            <div className={styles.lotterySmallBall} style={{
                backgroundImage: `url(${image})`,
            }}>
                {number}
            </div>
            <div style={{ fontSize: '12px', paddingTop: '6px' }}>{description}</div>
        </div>
    );
};

const LotteryTable = ({ lotteryData }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const tableHeaders = isMobile
        ? ['期號', '中獎號碼']
        : ['期號', '開獎時間', '中獎號碼', '開獎回放'];

    return (
        <table className={styles.lotteryTable}>
            <thead>
                <tr>
                    {tableHeaders.map((header, index) => (
                        <th key={header} className={`column-${index + 1}`}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {lotteryData.map((row, index) => (
                    <tr key={index}>
                        <td className={styles.lotteryTableColumn1}>{row.period}</td>
                        <td className={styles.lotteryTableColumn2}>{row.openTime}</td>
                        <td className={styles.lotteryTableColumn3}>
                            <div className={styles.lotteryRow}>
                                {row.numbers.slice(0, -1).map((ball, index) => (
                                    <SmallBall key={index} number={row.numbers[index]} image={row.color[index]} description={row.desc[index]} />
                                ))}
                                <span className={styles.smallPlusSign}>+</span>
                                <SmallBall key={row.numbers.length - 1} number={row.numbers[row.numbers.length - 1]} image={row.color[row.numbers.length - 1]} description={row.desc[index]} />
                            </div>
                        </td>
                        <td className={styles.lotteryTableColumn4}>
                            <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>回放</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

};


export default Tab2;