import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './pages/Header';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Tab4 from './pages/Tab4';
import Tab5 from './pages/Tab5';
import Tab6 from './pages/Tab6';
import Footer from './pages/Footer'

function App() {

  useEffect(() => {
    document.title = "香港六合彩 - 香港六合彩开奖结果 - 香港六合彩开奖记录";
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Header />
        <main className="content">
          <Routes>
            <Route path="/" element={<Tab1 />} /> {/* Render Tab1 for the base URL */}
            <Route path="/tab1" element={<Tab1 />} />
            <Route path="/tab2" element={<Tab2 />} />
            <Route path="/tab3" element={<Tab3 />} />
            <Route path="/tab4" element={<Tab4 />} />
            <Route path="/tab5" element={<Tab5 />} />
            <Route path="/tab6" element={<Tab6 />} />
            {/* <Route path="/tab7" element={<Tab7 />} /> */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
