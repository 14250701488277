import React, { useState } from 'react';
import styles from './Tab4.module.css';

function Tab4() {
    const options = ["FAQ", "技術支持", "公告列表"];
    const defaultIndex = options.indexOf("FAQ"); // Default index for FAQ

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <div>
            <SegmentedControl
                options={options}
                selectedIndex={selectedIndex}
                onOptionSelected={handleOptionSelected}
            />
            {/* Conditionally render content based on selected index */}
            {selectedIndex === 0 && <DescriptionFAQ />}
            {selectedIndex === 1 && <DescriptionFirst />}
            {selectedIndex === 2 && <DescriptionSecond />}
        </div>
    );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

function DescriptionFAQ() {
    return (
        <>
            <div className={styles.FAQTitle}>
                澳門六合彩 FAQ
            </div>
            <div className={styles.FAQDesc}>
                1、澳門六合彩在什麼時間段可以購買？<br />
                答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。<br />
                <br />
                2、需要多少錢才可以購買？<br />
                答：澳門六合彩注項單位為每注10澳門幣。<br />
                <br />
                3、單張彩票可以購買多少金額？<br />
                答：購買者可對其選定的投注號碼進行單倍或多倍投注，投注倍數範圍為1-100倍。單張彩票的投注金額最高不得超過1000澳門幣。<br />
                <br />
                4、我應如何確認是否中獎？<br />
                答：您可在澳門六合彩的銷售站點查詢確認、或在澳門六合彩官網的「開獎公告」處查詢核對中獎號碼。<br />
                <br />
                5、如何能進一步確認開獎結果的正確性？<br />
                答：您可在澳門六合彩官網查看即時開獎公告與開獎視頻，或查看歷史開獎期號的視頻回放。<br />
                <br />
                6、購買彩券後可以退款不買嗎？<br />
                答：抱歉，根據《奖券规例》規定獎券彩票一經發出給參加者或經參加者確認，參加者不得以任何理由更換、取消或撤回。<br />
                <br />
                7、中獎之後領獎的步驟是什麼樣的？<br />
                答：所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。逾期未兌獎視為棄獎，棄獎獎金納入彩票公益金。<br />
                <br />
                8、如果我的彩券受到污染或損壞是否可以兌獎？<br />
                答：根據《奖券规例》規定任何獎券彩票（包括損毀彩票）不會獲得派發獎金或發還退款，除非該彩票可憑其獨有的序列號碼辨證。<br />
                <br />
                <br />
            </div>
        </>
    );
}

function DescriptionFirst() {
    return (
        <>
            <div className={styles.FAQTitle}>
                技術支持
            </div>
            <div className={styles.FAQDesc}>
                最低硬件系統要求：<br />
                1、可以接上互聯網的計算機；<br />
                2、區域寬帶；<br />
                3、Windows , Mac OS X , Linux , Android操作系統其中之一；<br />
                4、Internet Explorer瀏覽器v10.0 或以上，Mozilla Firefox (瀏覽器v3.0 或以上)，Opera (瀏覽器v8.0 或以上)，Chrome(瀏覽器v6.0 或以上)，Safari (瀏覽器v4.0 或以上)。<br />
                <br />
                <br />
            </div>
        </>
    );
}

function DescriptionSecond() {
    return (
        <>
            <div className={styles.FAQTitle}>
                公告列表
            </div>
            <div className={styles.line}></div>
            <div className={styles.container}></div>
        </>
    );
}

export default Tab4;
