import React, { useState } from 'react';
import styles from './Tab5.module.css';

function Tab5() {
    const options = ["關於我們", "遊戲規範", "獎券規例"];
    const defaultIndex = options.indexOf("關於我們"); // Default index for 關於我們

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

    const handleOptionSelected = (index) => {
        console.log('Selected index:', index);
        setSelectedIndex(index);
    };

    return (
        <>
            <div className={styles.banner}>
                <div>
                    <img src="https://macaujc.org/assets/about-eab1f5f4.png" alt="" width="100%" />
                </div>
            </div>
            <div>
                <SegmentedControl
                    options={options}
                    selectedIndex={selectedIndex}
                    onOptionSelected={handleOptionSelected}
                />
                {/* Conditionally render content based on selected index */}
                {selectedIndex === 0 && <DescriptionZero />}
                {selectedIndex === 1 && <DescriptionFirst />}
                {selectedIndex === 2 && <DescriptionSecond />}
            </div>
        </>

    );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
    const handleOptionClick = (index) => {
        onOptionSelected(index);
    };

    return (
        <div className={styles.segmentedControl}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

function DescriptionZero() {
    return (
        <>
            <div className={styles.zeroTitle}>
                關於我們
            </div>
            <div className={styles.zeroDesc}>
                澳門六合彩是從1至49個號碼中選出六個為中獎號碼的獎券，由「賽馬會獎券奧門分公司」經辦。
                澳門六合彩每晚攪珠一次，並於網絡現場直播。
            </div>
            <p className={styles.title}>玩法規則</p>
            <div className={styles.line}></div>
            <div className={styles.container}>
                <br />
                <div className={styles.logo} >
                    <div>
                        <img src="https://macaujc.org/assets/logo-5fb13fd4.png" alt="" width="180px" />
                    </div>
                </div>
                <ZeroDataTable />
                <br />
                <br />
            </div>
        </>
    );
}

function DescriptionFirst() {
    return (
        <>
            <p className={styles.title}>遊戲規範</p>
            <div className={styles.line}></div>
            <div className={styles.container}>
                <FirstDataTable />
                <p className={styles.desc}>
                    獎金基金是指每期攪珠的總投注額的百分之五十四。 餘下的百分之四十六將根據博彩稅條例分配如下：<br />
                    (i) 獎券收益的徵稅(百分之二十五)；<br />
                    (ii) 獎券基金(百分之十五)；<br />
                    (iii) 賽馬會獎券奧門分公司的佣金(百分之六)。<br />
                    第一組、第二組及第三組獎金基金之分配百分率可作調整，以盡量確保在任何時間內，每一有關獎金以一注項單位作計算：<br />
                    (i) 第一組獎金應最少為第二組獎金的兩倍；<br />
                    (ii) 第二組獎金應最少為第三組獎金的兩倍；<br />
                    (iii) 第三組獎金應最少為第四組獎金的兩倍；<br />
                    (iv) 第四組、第五組、第六組及第七組獎金亦可根據獎券規例而被調整。<br />
                    中獎登記<br />
                    若獲中之獎金為每十元一注超過五百萬元，所有中獎彩票持票人士或經由電話投注服務的中獎人士，請於指定時間(公佈攪珠結果後至翌日下午5時)致賽馬會獎券奧門分公司在線服務系統登記。<br />
                    領取資金<br />
                    所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。<br />
                    獎券規例<br />
                    所有獎券注項均受賽馬會獎券奧門分公司規例所規限。如此通告與獎券規例有任何出入之處，一概以獎券規例為準。<br />
                    <br />
                </p>
            </div>
        </>
    );
}

function DescriptionSecond() {
    return (
        <>
            <div className={styles.line}></div>
            <div className={styles.container}>
                <p className={styles.title}>最低硬件系統要求：</p>
                <p className={styles.subtitle}>序言</p>
                <p className={styles.desc}>
                    本規例由賽馬會獎券奧門分公司董事局根據其章程大綱制定，藉以規管所有獎券活動。 <br />
                    本規例可隨時由賽馬會獎券奧門分公司董事局修訂，而有關修訂條文於賽馬會獎券奧門分公司董事局通過後生效。 <br />
                    有關修訂本規例的通告及／或根據本規例作出的決定及／或宣佈的通告，將張貼於營辦者總部的告示板及獎券發售地點的告示板，而不作另行通告。<br />
                    本規例可於營辦者總部及獎券發售地點供索閱，並於馬會網站供查閱。 <br />
                    所有向賽馬會獎券奧門分公司提交注項的參加者均被視作已同意接受本規例的約束及其所有的修訂條文、以及根據本規例不時作出的決定及宣佈的約束。<br />
                    <br />
                </p>
                <p className={styles.subtitle}>第一章 定義</p>
                <p className={styles.desc}>
                    在本規例中，各詞的定義如下：<br />
                    「膽拖」指參加者選取的一個或以上的號碼，而該號碼或該等號碼均被包括在與參加者選定的其他號碼組成的每一個單式注項內。<br />
                    「膽拖注項」指一項注項，其中參加者選取一個或以上的號碼作為膽拖。<br />
                    「投注戶口」具有《博彩設施規例》所賦予的涵義。<br />
                    「《博彩設施規例》」指馬會的博彩設施規例。<br />
                    「投注終端機」具有《博彩設施規例》所賦予的涵義。<br />
                    「現金」指紙幣或硬幣形式的澳門幣。<br />
                    「現金券」具有《博彩設施規例》所賦予的涵義。<br />
                    「機會」每一期攪珠中：<br />
                    (a) 對於獎券彩票中參與一個或以上單式注項者，指每一單式注項；<br />
                    (b) 對於獎券彩票中參與複式注項者，指由組成該複式注項之號碼可能排列組合出的每一單式注項；<br />
                    (c) 對於獎券彩票中參與膽拖注項者，指由作為膽拖的號碼與所有該獎券彩票中的其他號碼按照規定的數目組成的每一單式注項；<br />
                    (d) 對於包含單式注項之電腦票，指其中的每一單式注項；<br />
                    (e) 對於包含一隨機注項且該隨機注項同時是一複式注項之電腦票，指由組成該隨機注項之號碼可能排列組合出的每一單式注項。<br />
                    「馬會」指賽馬會奧門分公司。<br />
                    「電腦紀錄」指由營辦者所使用的電腦所出的紀錄。<br />
                    「投注寶」具有《博彩設施規例》所賦予的涵義。<br />
                    「損毀彩票」指任何遭撕毀、損壞或被塗改的實物獎券彩票，其損毀程度達致馬會認為難以辨認載於票上的資料的程度。<br />
                    「攪珠」指選擇攪出號碼及特別號碼的程序。<br />
                    「攪出號碼」指在攪珠時自動選出的首六個號碼。<br />
                    「覆述」指參加者在通過電話投注服務口頭作出注項時，職員就其注項向參加者作出的口頭覆述，且該口頭覆述載於馬會的錄音系統內。<br />
                    「注項」包括：<br />
                    (a) 一有效注項；<br />
                    (b) 一名參加者的一項提交，透過獎券彩票或營辦者使用的任何設施，提交他對某一個或多個彩池中對某一期攪珠注項或一項多期攪珠注項的選擇，而該項提交涉及的注項可根據本規例而納入有關彩池內；<br />
                    (c) 一單式注項、複式注項、膽拖注項或隨機注項。<br />
                    「特別號碼」指在攪珠時自動選出的第七個號碼。<br />
                    「設施」具有《博彩設施規例》所賦予的涵義。在文義許可的情況下，「設施」包括投注地點。<br />
                    「互聯網」具有《博彩設施規例》所賦予的涵義。<br />
                    「互聯網設施」具有《博彩設施規例》所賦予的涵義。<br />
                    「注項款額」指參加者在一期攪珠中就一個或多個注項應支付的確實金額。<br />
                    「獎券」具有《博彩稅條例》所賦予的涵義。<br />
                    「獎券代理商」指經營辦者批準可由參加者在其營業場所提交注項及／或領取獎金或退款的任何人士、商號或公司。<br />
                    「政府獎券基金」具有《博彩稅條例》所賦予的涵義。<br />
                    「獎券發售地點」指馬會的沙田及跑馬地馬場、場外的投注中心以及參加者可就獎券提交注項的任何地點。在文義許可的情況下，此定義包括馬會的官方網站及其他由營辦者核準或經營，容許透過互聯網提交注項的網站。<br />
                    「獎券彩票」指由投注終端機確定為有效後發出給參加者的實物票據；而對於營辦者使用而並無實物票據發出的任何設施，詳列該項注項的正式紀錄的部份，則被當作為有關的獎券彩票。<br />
                    「多期攪珠注項」指一項為參與營辦者所許可的連續多期攪珠而提交的注項。<br />
                    「複式注項」指一項所有可能的組合均分別構成單式注項。<br />
                    「正式紀錄」：<br />
                    (a) 除以下副款第 (b) 條另有規定外，當應用於獎券彩票或現金券時，或應用於經投注終端機、互聯網設施系統處理的交易時，「正式紀錄」指電腦紀錄；<br />
                    (b) 當應用於參加者通過電話投注服務向職員口頭作出注項時，「正式紀錄」指由馬會的錄音系統證明參加者本人確認的覆述詳情；如無參加者的確認或錄音，則指電腦紀錄。<br />
                    「營辦者」指賽馬會獎券奧門分公司。<br />
                    「部份注項單位」指由營辦者在任何特定的獎券中接受的部份注項單位。<br />
                    「參加者」指任何提交注項的人士。<br />
                    「百分比扣數」指從每一個彩池或其任何部份中，依據《博彩稅條例》不時規定的稅率扣除的獎券稅、政府獎券基金分配及支付予營辦者的佣金總和。<br />
                    「彩池」指每期攪珠由參加者作出之有效注項的總金額。<br />
                    「獎金」指宣佈支付予每張獲獎彩票上每一注項單位的款項。<br />
                    「獎金基金」指彩池減去百分比扣數。<br />
                    「電腦票」指一張每一注項均為隨機注項的獎券彩票。<br />
                    「隨機注項」指一項由營辦者使用的電腦隨機產生的號碼而組成的注項。<br />
                    「退款」指無資格參與彩池的每項有效注項的金額或任何根據本規例由營辦者向參加者退還的注項金額。<br />
                    「本規例」指在此列出的規例及其不時的修訂。「自助售票機」具有《博彩設施規例》所賦予的涵義。<br />
                    「一期攪珠注項」指為參與一指定攪珠或提交注項後的第一期攪珠的注項。<br />
                    「單式注項」指一項僅僅包含了某種特定獎券規定的最少選取號碼的注項。<br />
                    「職員」指馬會及／或營辦者的僱員或獎券代理商在獎券發售地點僱用的僱員，並包括與馬會、營辦者及／或獎券代理商訂有合約協定的任何獨立承包人士的僱員。<br />
                    「固定指示」指營辦者提供的設施，讓參加者能以相同的注項，參與連續期數的攪珠。<br />
                    「電話投注服務」具有《博彩設施規例》所賦予的涵義。<br />
                    「注項單位」指營辦者規定每一注項應提交的最低金額，且以該金額為基礎計算獎金。<br />
                    「注項單位值」指參加者於每一注項所付的款額。<br />
                    「有效注項」指經過本規例的程序後納入彩池內的每一注項。<br />
                    「無效獎券彩票」指任何被宣佈為無效的獎券彩票。<br />
                    「獲獎機會」指參加者的有效注項中任何有資格獲派獎金的機會。<br />
                    「獲獎彩票」指任何包含獲獎機會的獎券彩票。<br />
                    <br />
                    <br />
                </p>
            </div>
        </>
    );
}

function FirstDataTable() {

    const tableData = [
        {
            name: '各組獎金',
            value: '獎金分配(每澳門幣$10一注)',
        },
        {
            name: '第一組獎金',
            value: '45% x (獎金基金減去第四、五、六及七組的總獎金〕+中獎單位每期頭獎獎金基金訂為不少於澳門幣800萬元。',
        },
        {
            name: '第二組獎金',
            value: '15% × 〔獎金基金減去第四、五、六及七組的總獎金〕+中獎單位',
        },
        {
            name: '第三組獎金',
            value: '40% x 〔獎金基金減去第四、五、六及七組的總獎金〕+中獎單位',
        },
        {
            name: '第四組獎金',
            value: '每注澳門幣9,600元',
        },
        {
            name: '第五組獎金',
            value: '每注澳門幣640元',
        },
        {
            name: '第六組獎金',
            value: '每注澳門幣320元',
        },
        {
            name: '第七組獎金',
            value: '每注澳門幣40元',
        },
    ];

    return (
        <>
            <br />
            <br />
            <div className={styles.blackContainer}>
                <p className={styles.desc}>獎金的分配須受賽馬會獎券奧門分公司的獎券規例所約制。<br />
                    獎金大致分配如下：<br /></p>
            </div>
            <table className={styles.table}>
                <tbody>
                    {tableData.map((row) => (
                        <tr key={row.name}>
                            <td>{row.name}</td>
                            <td>{row.value}</td>
                        </tr>
                    ))}
                </tbody>
                <br />
            </table>
        </>

    );
};

function ZeroDataTable() {

    const tableData = [
        {
            index: '頭獎',
            name: '選中6個「攪出號碼」',
            value: '獎金會因應該期獲中頭獎注數而有所不同，每期頭獎獎金基金訂為不少於澳門幣800萬元。',
        },
        {
            index: '二獎',
            name: '選中5個「攪出號碼」+「特別號碼」',
            value: '獎金會因應該期獲中二獎注數而有所不同',
        },
        {
            index: '三獎',
            name: '選中5個「攪出號碼」',
            value: '獎金會因應該期獲中三獎注數而有所不同',
        },
        {
            index: '四獎',
            name: '選中4個「攪出號碼」+「特別號碼」',
            value: '固定獎金澳門幣9,600元',
        },
        {
            index: '五獎',
            name: '選中4個「攪出號碼」',
            value: '固定獎金澳門幣640元',
        },
        {
            index: '六獎',
            name: '選中3個「攪出號碼」+「特別號碼」',
            value: '固定獎金澳門幣320元',
        },
        {
            index: '七獎',
            name: '選中3個「攪出號碼」',
            value: '固定獎金澳門幣40元',
        },
    ];

    return (
        <>
            <br />
            <br />
            <div className={styles.blackContainer}>
                <p className={styles.desc}>
                    澳門六合彩注項單位為每注10澳門幣。選擇複式或膽拖注項時，可以每注澳門幣$5「部份注項單位」投注。有關之獎金則根據每注「部份注項單位」佔注項單位的份數計算。每期六合彩攪珠均從49個號碼中攪出七個號碼。首六個號碼稱為「攪出號碼」，而第七個號碼稱為「特別號碼」。
                    獎項詳情如下：
                </p>
            </div>
            <table className={styles.table}>
                <tbody>
                    {tableData.map((row) => (
                        <tr key={row.name}>
                            <td>{row.index}</td>
                            <td>{row.name}</td>
                            <td>{row.value}</td>
                        </tr>
                    ))}
                </tbody>
                <br />
            </table>
        </>

    );
};

export default Tab5;
